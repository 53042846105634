<template>
  <div>
    <div
      ref="introContainer"
      class="intro-container row-24 m-0 justify-center items-center"
    >
      <h1 class="intro-title col-20 md:col-14 lg:col-16" v-html="payload.introtext" />
      <ScrollIndicator />
    </div>

    <section
      id="movie-section"
      ref="movieSection"
      class="movie-section bg-white row-24 m-0"
    >
      <div
        ref="grid"
        class="grid movie-container col-22 offset-1 md:col-16 md:offset-6 lg:col-18 lg:offset-4 xl:col-16 m-0 p-0"
      >
        <Go
          v-for="movie of randomizedMovies"
          :key="movie.id"
          :to="movie.link"
          class="grid-item movie-wrapper-link element-item"
          :class="movie.filmtyp.length ? movie.filmtyp.map((el) => el.slug) : ''"
        >
          <div class="movie-box">
            <div class="movie-title-box">
              <h2 class="movie-title" v-html="movie.filmtitel" />
              <h4 class="movie-subtitle">
                by {{ movie.regie }}
              </h4>
            </div>
            <video
              :src="movie.movieloop.length ? movie.movieloop[0].link : null"
              autoplay
              muted
              loop
              playsinline
              disablePictureInPicture
              controlsList="nodownload noremoteplayback"
            />
          </div>
        </Go>
      </div>
    </section>
    <!-- this element gets teleported to #navBox (in Navigation) -->
    <teleport to="#filterBox">
      <div :style="{ opacity: visible ? 1 : 0, transition: 'opacity 300ms ease' }">
        <p class="filter-title uppercase">
          Filter
        </p>
        <input
          id="all"
          type="checkbox"
          class="hidden filter allBtn"
          @input="activeFilters = []"
        >
        <label for="all" v-text="'Alle Projekte'" />

        <div
          v-for="(name, slug) of categories"
          :key="slug"
          class="block w-auto"
        >
          <input
            :id="slug"
            :checked="activeFilters.includes(slug)"
            type="checkbox"
            class="hidden filter"
            :value="slug"
            @click="activeFilters = [ slug ]"
          >
          <label :for="slug" v-text="name" />
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>

import Isotope from 'isotope-layout';

import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      movies: this.payload.movies,
      activeFilters: [],
      hasScrolled: false,
      visible: false,
    };
  },
  computed: {
    categories() {
      const categories = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const movie of this.movies) {
        // eslint-disable-next-line no-restricted-syntax
        for (const tag of movie.filmtyp) {
          if (!categories[tag.slug]) categories[tag.slug] = tag.title;
        }
      }
      return categories;
    },
    randomizedMovies() {
      const copiedMovies = [...this.movies];
      return this.shuffleMovies(copiedMovies);
    },
  },
  watch: {
    activeFilters() {
      // I guess this tells isotope to rearrange and filter according to the elements in activefilters?
      this.iso.arrange({
        filter: this.activeFilters.length ? `.${this.activeFilters.join(', .')}` : '*',
      });
    },
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll, { passive: true });

    this.$refs.introContainer.addEventListener('click', this.scrollDown);
    // window.addEventListener('scroll', this.scrollToContent);

    this.iso = new Isotope(this.$refs.grid, {
      percentPosition: true,
      masonry: {
        gutter: 20,
      },
    });

    const link = document.querySelector('#portfolio-link');
    link.addEventListener('click', this.scrollDown);
  },
  unmounted() {
    setTimeout(() => this.iso && this.iso.destroy(), 500);

    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    shuffleMovies(array) {
      let currentIndex = array.length; let
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    },
    onScroll() {
      this.visible = window.scrollY > window.innerHeight;
    },
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
    // scrollToContent() {
    //   if (!this.hasScrolled) {
    //     window.scroll({
    //       top: window.innerHeight,
    //       behavior: 'smooth',
    //     });
    //   }
    //   this.hasScrolled = true;
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.intro-container {
  background-color: var(--color-black);
  .intro-title {
    color: var(--color-white);
    :deep(a) {
      color: var(--color-white);
      &:hover {
        color: var(--color-red);
      }
    }
  }
  .scroll-indicator {
    :deep(svg) {
      color: var(--color-white);
    }
  }
}

.movie-section {
  padding: 4rem 0 0 0;
  .movie-container {
    position: relative;
  }
}

.movie-wrapper-link {
  display: block;
  width: 100%;
  height: 250px;
  &:not(:last-child) {
    margin-bottom: 2rem;
    @screen sm {
      margin-bottom: 4rem;
    }
    @screen md {
      margin-bottom: 8rem;
    }
    @screen lg {
      margin-bottom: 12rem;
    }
  }

   @screen sm {
      height: 500px;
    }
    @screen md {
      height: 500px;
    }
    @screen lg {
      height: 900px;
    }

  /* // https://trello.com/c/YakZKy2e/35-schaufilm
  &:nth-child(3n+1) {
    @screen sm {
      height: 500px;
    }
    @screen md {
      height: 500px;
    }
    @screen lg {
      height: 900px;
    }
  }
  &:nth-child(3n+2) {
    @screen sm {
      width: 75%;
      height: 400px;
      margin-left: auto;
    }
    @screen md {
      width: 80%;
      height: 450px;
    }
    @screen lg {
      width: 66%;
      height: 750px;
    }
  }
  &:nth-child(3n+3) {
    @screen sm {
      width: 80%;
      height: 350px;
    }
    @screen md {
      height: 400px;
    }
    @screen lg {
      height: 650px;
    }
  }
  */
}

.movie {
  &-box {
    position: relative;
    width: 100%;
    height: 100%;
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-title-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @screen md {
      width: 50%;
    }
    .movie-title {
      :deep(p) {
        font-size: 1.75rem;
        text-align: center;
        font-family: var(--font-primary);
        color: var(--color-yellow);
        line-height: 1.2;
        margin: 0;
        @screen md {
          font-size: 2.5rem;
        }
        @screen lg {
          font-size: 3rem;
        }
      }

    }
    .movie-subtitle {
      text-align: center;
      color: var(--color-white);
      font-size: 1rem;
      @screen md {
        font-size: 1.1rem;
      }
      @screen lg {
        font-size: 1.25rem;
      }
    }
  }
}

// isotope feature, makes height transitions more slowly when items disappear due to filtering
.grid {
  transition: height .6s ease;
}

</style>
